import axios from 'axios'

const handleCall = (response) => {
	if (!response || !response.data) return;

	if (Array.isArray(response.data)) {
		let result = [];

		response.data.forEach(element => {
			let r = {
				_id: element.id,
				id: element.id,
			};

			Object.keys(element.attributes).forEach(key => {
				r[key] = element.attributes[key];
			});

			result.push(r);
		});

		return { data: result };
	} else {
		return response;
	}
}

export default class API {
	static get(moduleName, type) {
		return new Promise(async (resolve, reject) => {
			try {
				const opts = this._opts(moduleName, 'get');
				if (type == 'file') {
					opts.responseType = 'blob'
				};
				const response = await axios(opts);

				resolve(handleCall(response.data));
			} catch (err) {
				console.error(JSON.stringify(err.response));
				if (err.response && err.response.status == 401) {
					localStorage.clear();
					window.location = '/';
				}
				reject(err);
			}
		})
	}

	static post(moduleName, data) {
		return new Promise(async (resolve, reject) => {
			try {
				const opts = this._opts(moduleName, 'post', data);
				const response = await axios(opts);

				if (moduleName == "auth/local") {
					resolve(response);
				} else {
					resolve(handleCall(response.data));
				}
			} catch (err) {
				console.error(JSON.stringify(err.response));
				if (err.response && err.response.status == 401) {
					localStorage.clear();
					window.location = '/';
				}
				reject(err);
			}
		})
	}

	static postForm(moduleName, data) {
		return new Promise(async (resolve, reject) => {
			try {
				const opts = this._opts(moduleName, 'post', data);
				opts.headers['Content-Type'] = 'multipart/form-data';
				const response = await axios(opts);

				resolve(handleCall(response.data));
			} catch (err) {
				if (err.response && err.response.status == 401) {
					localStorage.clear();
					window.location = '/';
				}
				reject(err);
			}
		})
	}

	static put(moduleName, data) {
		return new Promise(async (resolve, reject) => {
			try {
				const opts = this._opts(moduleName, 'put', data);
				const response = await axios(opts);

				resolve(handleCall(response.data));
			} catch (err) {
				if (err.response && err.response.status == 401) {
					localStorage.clear();
					window.location = '/';
				}
				reject(err);
			}
		})
	}

	static delete(moduleName) {
		return new Promise(async (resolve, reject) => {
			try {
				const opts = this._opts(moduleName, 'delete');
				const response = await axios(opts);

				resolve(handleCall(response.data));
			} catch (err) {
				if (err.response && err.response.status == 401) {
					localStorage.clear();
					window.location = '/';
				}
				reject(err);
			}
		})
	}

	static _opts(moduleName, method, data) {
		let token = localStorage.getItem('token');
		const opts = {
			method: method,
			url: `${this.apiUrl()}/${moduleName}`
		};

		if (token) {
			opts.headers = {
				'Authorization': `Bearer ${token}`
			};
		}

		if (data) {
			opts.data = data
		};

		return opts;
	};

	static apiHostServer1() {
		return process.env.VUE_APP_API_SERVER_1;
	};

	static apiHostServer2() {
		return process.env.VUE_APP_API_SERVER_2;
	};

	static apiPort() {
		return process.env.VUE_APP_API_PORT;
	};

	static apiUrl() {
		return `${process.env.VUE_APP_API_CLOUD}`;
	};

	static uaucUrl() {
		return `${process.env.VUE_APP_UAUC_API}`;
	};
};